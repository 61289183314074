import React from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: '1',
        title: 'Website Development',
        description: 'We use Latest Development Technologies like - ReactJS, NodeJS, Wordpress, Etc'
    },
    {
        icon: '2',
        title: 'Digital Marketing',
        description: 'From Search Engine Marketing to Social Media Marketing, we are expert in all!'
    },
    {
        icon: '3',
        title: 'App Development',
        description: 'We can deliver you with Powerful Applications Based on Android Studio and Flutter'
    },
    {
        icon: '4',
        title: 'Graphics Designing',
        description: 'We have expert Designers who can provide you with Quality Posts, Banners, Logos, Reels, IGTV and reach the targeted Audience.'
    },
]

const ServiceFour = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-3 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                <div className="line"></div>
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceFour;