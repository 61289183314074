import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import HeaderOne from "../../common/header/HeaderOne";
import FooterOne from "../../common/footer/FooterOne";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import ServiceOne from "./ServiceOne";
import ServiceTwo from "./ServiceTwo";
import ServiceThree from "./ServiceThree";
import ServiceFour from "./ServiceFour";
import ServiceFive from "./ServiceFive";
import Separator from "../separator/Separator";

const Service = () => {
    return (
        <>

            <SEO title="Service || developer.buddy" />
            <Layout>
                <BreadcrumbOne 
                    title="Best Quality and Most Affordable services on the Globe"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Service"
                />
                <div className="main-content">

                    {/* Start Service Area  */}
                    <div className="rn-service-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Web Development Services"
                                        title = "Technologies We Use"
                                        description = "We built the Website using every famous Technology, <br /> With Beautiful Graphics and Lightning Speed Delivery."
                                     />
                                </div>
                            </div>
                            <ServiceOne 
                                serviceStyle = "service__style--1 bg-color-blackest radius mt--25"
                                textAlign = "text-center"
                             />
                        </div>
                    </div>
                    {/* End Service Area  */}

                    
                    <Separator />

                   

                    <Separator />

                    {/* Start Service Area  */}
                    <div className="rn-service-area rn-section-gap ">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Digital Marketing"
                                        title = "Services provided for Digital Marketing."
                                        description = "From Search Engine to Social Media, <br /> We Offer Every step as a service required to Market your Product or Services."
                                     />
                                </div>
                            </div>
                            <ServiceTwo 
                                cardStyle = "card-style-1"
                                textAlign = "text-left"
                             />
                        </div>
                    </div>
                    {/* End Service Area  */}

                    <Separator />

                    {/* Start Service Area  */}
                    <div className="rn-service-area rn-section-gap ">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Graphics Designing"
                                        title = "Checkout of Graphics Designing Services"
                                        description = "Using Tools Like Adobe, Canva, Figma, Filmora <br />We Offer every kind of servies related to Graphics."
                                     />
                                </div>
                            </div>
                            <ServiceThree 
                                serviceStyle = "service__style--2"
                                textAlign = "text-center"
                             />
                        </div>
                    </div>
                    {/* End Service Area  */}

                    <Separator />


                    


                    <Separator />


                   
                    

                    <Separator />

                    {/* Start Service Area  */}
                    <div className="rn-service-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Android Development"
                                        title = "Services for Android Development"
                                        description = "We create, Update Any Kind of Android Apps <br />Or Connect your Existing Website to Android App"
                                     />
                                </div>
                            </div>
                            <ServiceFive 
                                serviceStyle = "gallery-style"
                                textAlign = "text-left"
                            />
                        </div>
                    </div>
                    {/* End Service Area  */}

                    
                </div>

            </Layout>
            
        </>
    )
}

export default Service;
