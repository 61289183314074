import React from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        image: '/images/service/icon-01.jpg',
        title: 'Logo Designing',
        description: 'Using Tools like Photoshop, Canva, AutoCad, We will built you with High Resolution Logos with various Color/style Combinations'
    },
    {
        image: '/images/service/icon-02.jpg',
        title: 'Banners and Flyers',
        description: 'We create the Art of Perfection with our banners and Flyers, Like Selling an Online course or Selling a Product '
    },
    {
        image: '/images/service/icon-03.jpg',
        title: 'Video Making and Editing',
        description: 'We provide FaceCam as well as Animated videos, with very clear words and Visuals'
    }
]
const ServiceThree = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="inner">
                                <div className="image">
                                    <img src={`${val.image}`} alt="card Images" />
                                </div>
                                <div className="content">
                                    <h4 className="title"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceThree;