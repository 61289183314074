import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const TimelineData = [
    {
        id: "1",
        title: "Undersating The Vision of the Client",
        description: "We deeply ask the Clients about their vision they manifest for their Growth",
    },
    {
        id: "2",
        title: "Collecting Necessary Resources",
        description: "We collect all the resources like Content, Taglines, Graphics",
    },
    {
        id: "3",
        title: "Analyse the Competitors and Build Better Strategy",
        description: "Analysing the Products and Services also the internal working of the Competitors and Beat them with the things they are lagging with.",
    },
    {
        id: "4",
        title: "Execution as per the Strategies we Created",
        description: "When everything is ready to go, we start the Execution with A/B Testings and then the final Go with Website, Social Media, Landing Pages.",
    },
]

const TimelineTwo = ({classVar}) => {
    return (
        <div className="timeline-style-two bg-color-blackest">
            <div className="row row--0">
                {/* Start Single Progress */}
                {TimelineData.map((data , index) =>(
                    <div className={`col-lg-3 col-md-3 rn-timeline-single ${classVar}`} key={index}>
                        <div className="rn-timeline">
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <h6 className="title">{data.title}</h6>
                            </ScrollAnimation>
                            <div className="progress-line">
                                <div className="line-inner"></div>
                            </div>
                            <div className="progress-dot">
                                <div className="dot-level">
                                    <div className="dot-inner"></div>
                                </div>
                            </div>
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <p className="description">{data.description}</p>
                            </ScrollAnimation>
                        </div>
                    </div>
                ))}
                {/* End Single Progress */}
            </div>
        </div>
    )
}
export default TimelineTwo;