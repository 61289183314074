import React from 'react'
import { FiCheck, FiStar } from "react-icons/fi";

const AdvancePricingOne = () => {
    return (
        <div className="advance-pricing">
            <div className="inner">
                <div className="row row--0">
                    <div className="col-lg-6">
                        <div className="pricing-left">
                            <h3 className="main-title">Complete Development Package</h3>
                            <p className="description">This is the best package if you are looking for a one-stop solution</p>
                            <div className="price-wrapper">
                                <span className="price-amount">$500 <sup>/mo</sup></span>
                            </div>
                            <div className="pricing-btn-group">
                                <button className="btn-default">Purchase Now</button>
                                <button className="btn-default btn-border">Contact Now</button>
                            </div>
                            <div className="rating">
                                <a href="#rating"><FiStar /></a>
                                <a href="#rating"><FiStar /></a>
                                <a href="#rating"><FiStar /></a>
                                <a href="#rating"><FiStar /></a>
                                <a href="#rating"><FiStar /></a>
                            </div>
                            <span className="subtitle">rated 4.5/5 Stars in 500+ reviews.</span>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="pricing-right">
                            <div className="pricing-offer">
                                <div className="single-list">
                                    <h4 className="price-title">The things we'll manage</h4>
                                    <ul className="plan-offer-list">
                                        <li><FiCheck /> Professional Beautiful Website</li>
                                        <li><FiCheck /> Social Media Management</li>
                                        <li><FiCheck /> Graphics</li>
                                        <li><FiCheck /> CRM</li>
                                    </ul>
                                </div>
                                <div className="single-list mt--40">
                                    <h4 className="price-title">A little Elaboration:</h4>
                                    <ul className="plan-offer-list">
                                        <li><FiCheck /> Social Media Ads</li>
                                        <li><FiCheck /> Search Engine Ads</li>
                                        <li><FiCheck /> Web Hosting</li>
                                        <li><FiCheck /> Leads Generation</li>
                                        <li><FiCheck /> FaceCam Videos</li>
                                        <li><FiCheck /> Content Creation</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdvancePricingOne
